<template>
  <div class="row child-component tab-content">
      <div class="d-block row col-lg-12" style="margin-left:0px !important; margin-top:10px;">
        <span class="Profile-Unlock-Reque">Profile Unlock Request</span>
        <br>
        <span class="Submit-this-request">
Submit this request to unlock your profile
</span>
       </div>
  <div
    class="col-lg-7 col-md-8 col-sm-8 col-xs-6 mx-auto"
  style="margin-top:-89px;">

    <div class="d-block row col-lg-8 mx-auto">
        <div class="fieldborder" style="margin-top: -22px;">
         <label>ERROR MESSAGE </label>
               <OmniSelect
                  placeholder="Select Error Message"
                  name="Media"
                  :showLabel="false"
                  label="Select Media"
                  :options="ErrorMessages"
                  v-model="Profile.error"
                  @input="ErrorCheck();"
                />
        </div>
        <br>
         <div class="fieldborder" style="margin-top: -22px;">
         <label>Are you changing dollars on the buy?</label>
              <b-form-group class="">
            <b-form-radio-group
            style="margin-left:10px;"
              id="changingdoller"
              v-model="Profile.changingdoller"
              name="ChangingDoller"
            >
              <b-form-radio value="true">Yes</b-form-radio>
              <b-form-radio value="false">No</b-form-radio>
            </b-form-radio-group>
          </b-form-group>
        </div>
        <div class="fieldborder mt-n4" v-if="Profile.changingdoller == 'true'">
          <label for="axlocatoin" class="lbltxt">Original Buy Amount</label>
          <b-form-input
            autocomplete="off"
            id="OriginalBuyAmount"
            class="omniipclass formatDollars"
            v-model="Profile.OriginalBuyAmount"
            placeholder="Original Buy Amount"
            @blur="formatDollars('OriginalBuyAmount')"
          ></b-form-input>
        </div>
        <div class="fieldborder mb-3" v-if="Profile.changingdoller == 'true'">
          <label for="axlocatoin" class="lbltxt">New Buy Amount</label>
          <b-form-input
            autocomplete="off"
            id="NewBuyAmount"
            class="omniipclass formatDollars"
            v-model="Profile.NewBuyAmount"
            placeholder="New Buy Amount"
            @blur="formatDollars('NewBuyAmount')"
          ></b-form-input>
        </div>
         <div class="fieldborder" style="margin-top: -22px;">
         <label>Will your change create additional billing to the client? </label>
              <b-form-group class="">
            <b-form-radio-group
            style="margin-left:10px;"
              id="additionalbilling"
              v-model="Profile.additionalbilling"
              name="AdditionalBilling"
            >
              <b-form-radio value="true" :disabled="IsAdditionalBillingSelected">Yes</b-form-radio>
              <b-form-radio value="false" :disabled="IsAdditionalBillingNotSelected">No</b-form-radio>
            </b-form-radio-group>
          </b-form-group>
        </div>
        <div class="fieldborder" style="margin-top: -22px;">
         <label>Are you adjusting a previously cleared buy? </label>
              <b-form-group class="">
            <b-form-radio-group
            style="margin-left:10px;"
              id="clearedbuy"
              v-model="Profile.clearedbuy"
              name="ClearedBuy">
              <b-form-radio value="true">Yes</b-form-radio>
              <b-form-radio value="false">No</b-form-radio>
            </b-form-radio-group>
          </b-form-group>
        </div>

         <div class="fieldborder" style="margin-top: -22px;" v-if="Profile.clearedbuy=='true' || Profile.clearedbuy==true">
         <label>Do you have a revised invoice from the vendor to support this change? </label>
              <b-form-group class="">
            <b-form-radio-group
            style="margin-left:10px;"
              id="Revised-Invoice"
              v-model="Profile.RevisedInvoice"
              name="RevisedInvoice"
            >
              <b-form-radio value="true">Yes</b-form-radio>
              <b-form-radio value="false">No</b-form-radio>
            </b-form-radio-group>
          </b-form-group>
        </div>

        <label>Specify the time period of the buy? </label>
        <div class="row fieldborder">
        <vue-monthly-picker
     class="col-md-12 omniipclass"
     readonly="true"
     :disabled="isDisable"
     :monthLabels="locale"
     :clearOption="clearOption"
     :min="min"
     :max="max"
     @selected="handleSelect"
     v-model="Profile.month"
     :alignment="alignment"

     :selectedBackgroundColor="selectedBackgroundColor">
    </vue-monthly-picker>
    </div>
    <div>
    <div class="fieldborder" style="margin-top: -4px" v-show="Profile.RevisedInvoice=='true'" >
        <!-- <div class="fieldborder" style="margin-top: -4px" >     -->
         <label for="screenshot" class="icagencycontantemail">INVOICE COPY </label>
         <br>
          <label for="axlocatoin" class="lbltxt">ATTACH FILE</label>
          <vue-dropzone
              v-model="Profile.timeperiodefile"
              :duplicateCheck="true"
              ref="TimeFramemyVueDropzone"
              id="dropzone"
              :useCustomSlot="true"
              class="customdropzone"
              :include-styling="false"
              :options="dropzoneOptions"
              v-on:vdropzone-file-added="UploadedtoTimePeriodeDropzone"
              v-on:vdropzone-removed-file="RemovedFromTimePeriodeDropzone"

            >
              <div class="dropzone-custom-content">
                <p class="Drag-and-drop-file-h">
                  <b-img
                    style="
                      margin-top: -7px !important;
                      font-family: 'Open Sans';
                    "
                  />
                   Drag and drop file here or
                  <span class="draganddropspan"> browse </span>

                </p>
              </div>
            </vue-dropzone>
        </div>
      </div>
        <!-- <div class="row fieldborder">
              <div class="col-md-6">
                  <OmniSelect
                  placeholder="Select Quarter"
                  name="Media"
                  :showLabel="false"
                  label="Select Quarter"
                  :options="TimePart"
                  v-model="Profile.month"
                />
              </div>
              <div   class="col-md-6">
                <input type="text"  v-bind:class="{ errorborder : $v.Profile.year.numeric  && $v.Profile.year.$dirty , noterrorborder:  $v.Profile.year.numeric }"  minlength="4" maxlength="4" v-model="Profile.year" @blur="SetDirty('year')" @focus="ResetDirty('year')" @keyup="ValidateYear() " class="form-control" placeholder="Specify Year(YYYYY)" >
                <div v-show="validationtextbox && $v.Profile.year.$dirty" class="error-message-format">
                      Input must be 4 characters
                  </div>
                  <div v-show="!$v.Profile.year.numeric && $v.Profile.year.$dirty" class="error-message-format">
                      This field can only contain numerical values
                  </div>
              </div>
        </div>

        <div class="fieldborder" style="margin-top: 12px;">
         <label style="line-height:unset;">Does the timeframe specified above exceed one year from today’s request? </label>
              <b-form-group class="">
            <b-form-radio-group
            style="margin-left:10px;"
              id="time-only"
              v-model="Profile.timeframe"
              name="TimeFrame"
            >
              <b-form-radio value="true">Yes</b-form-radio>
              <b-form-radio value="false">No</b-form-radio>
            </b-form-radio-group>
          </b-form-group>
        </div> -->
        <div class="fieldborder" v-if="ShowDisclaimer" style="margin-top: -4px">
          <label for="axlocatoin" class="lbltxt">USER ERROR</label>
          <b-form-input
            autocomplete="off"
            id="clientcode"
            class="omniipclass"
            v-model="Profile.usererror"
            placeholder="Enter Your Error"
          ></b-form-input>
        </div>

<br>
        <div class="fieldborder" style="margin-top: -22px;">
        <OmniSelect
          v-model="Profile.entity"
          name="SELECT ENTITY"
          placeholder="Select Entity"
          :allowFiltering="true"
          :showSearch="true"
          multiple="true"
          :options="OMDEntity"
          @input="EntityChange()"
        />
        </div>

      <div class="fieldborder" style="margin-top: -12px;">
        <OmniSelect
          v-model="Profile.agency"
          name="AGENCY"
          placeholder="Select Agency"
          :allowFiltering="true"
          :showSearch="true"
          multiple="true"
          :options="Agencies"
        />
        </div>
        <div class="fieldborder" style="margin-top: -22px;">
        <OmniSelect
          v-model="Profile.toolkit"
          name="TOOLKIT"
          placeholder="Select Toolkit"
          :allowFiltering="true"
          :options="ToolKits"
          :showSearch="true"
          multiple="true"
        />
        <div class="fieldborder" style="margin-top: -4px" v-if="Profile.toolkit">
          <label for="SupplierName" class="lbltxt">{{PrintMediaLsit ? 'Mediaocean Supplier' : 'Network'}}</label>
          <custom-tooltip :content="'Supplier Code is the Publication Code.'"></custom-tooltip>
          <b-form-input
            autocomplete="off"
            id="SupplierName"
            class="omniipclass"
            v-model="Profile.SupplierName"
            placeholder="Enter Supplier Name"
            :maxLength="150"
          ></b-form-input>
        </div>
        </div>
            <div v-if="NetMediaList" class="fieldborder" style="margin-top: -22px;">
                <label>Media Type </label>
               <OmniSelect
                  placeholder="Select Media"
                  name="Media"
                  :showLabel="false"
                  label="Select Media"
                  :options="NetMediaLists"
                  v-model="Profile.NetMediaTypes"
                />
        </div>
        <div v-if="PrintMediaLsit" class="fieldborder" style="margin-top: -22px;">
                <label>Media Type </label>
               <OmniSelect
                  placeholder="Select Media"
                  name="Media"
                  :showLabel="false"
                  label="Select Media"
                  :options="PrintMediaLists"
                  v-model="Profile.PrintMediaTypes"
                />
        </div>
        <br>
        <div class="fieldborder" style="margin-top: -22px">
        <OmniSelect
          v-model="Profile.clientcode"
          name="SELECT CLIENT CODE"
          placeholder="Select Client Code"
          :allowFiltering="true"
          :showSearch="true"
          multiple="true"
          :options="ClientCodes"
        />
        </div>
        <div class="fieldborder mt-n4" v-if="Profile.clientcode">
          <label for="axlocatoin" class="lbltxt">Product</label>
          <b-form-input
            autocomplete="off"
            id="Product"
            class="omniipclass"
            v-model="Profile.Product"
            placeholder="Product"
            :maxLength="3"
          ></b-form-input>
        </div>
        <div class="fieldborder mb-3" v-if="Profile.clientcode">
          <label for="axlocatoin" class="lbltxt">Estimate</label>
          <the-mask type="text"
            :mask="['###', '###']"
            class="form-control"
            autocomplete="off"
            id="Estimate"
            :maxLength="3"
            v-model="Profile.Estimate"
            placeholder="Estimate"></the-mask>
        </div>
        <div class="fieldborder" style="margin-top: -4px">
          <label for="axlocatoin" class="lbltxt" v-if="NetMediaList">NET TOOLKIT EXPLANATION</label>
          <label for="axlocatoin" class="lbltxt" v-else-if="PrintMediaLsit">PRINT/PRISMA TOOLKIT EXPLANATION</label>
          <label for="axlocatoin" class="lbltxt" v-else>TOOLKIT EXPLANATION</label>
          <b-form-textarea
    id="textarea"
    placeholder="Enter Reason for Request"
    v-model="Profile.reason"
    :maxlength="500"
    rows="8"
    max-rows="8"
  ></b-form-textarea>
        </div>
        <div class="fieldborder" style="margin-top: -4px" v-if="PrintMediaLsit">
          <label for="axlocatoin" class="lbltxt">PRISMA CAMPAIGN LINK</label>
          <b-form-input
            autocomplete="off"
            id="clientcode"
            class="omniipclass"
            v-model="Profile.camplink"
            placeholder="Enter Prisma Campaign Link"
          ></b-form-input>
        </div>
        <div class="fieldborder" style="margin-top: -4px">
         <label for="screenshot" class="icagencycontantemail">SCREENSHOT OF ERROR </label>
         <br>
          <label for="axlocatoin" class="lbltxt">ATTACH FILE</label>
          <vue-dropzone
              v-model="Profile.userFile"
              :duplicateCheck="true"
              ref="myVueDropzone"
              id="dropzone"
              :useCustomSlot="true"
              class="customdropzone"
              :include-styling="false"
              :options="dropzoneOptions"
              v-on:vdropzone-file-added="UploadedtoDropzone"
              v-on:vdropzone-removed-file="RemovedFromDropzone"

            >
              <div class="dropzone-custom-content">
                <p class="Drag-and-drop-file-h">
                  <b-img
                    style="
                      margin-top: -7px !important;
                      font-family: 'Open Sans';
                    "
                  />
                   Drag and drop file here or
                  <span class="draganddropspan"> browse </span>

                </p>
              </div>
            </vue-dropzone>
        </div>
        <div class="fieldborder mt-1">
          <label>BPM approver</label>
          <omni-select placeholder="BPM approver" :showLabel="false" :options="BPMUsers" v-model="Profile.BPMApprover" />
        </div>
          <div class="mt-3 d-block agree-checkbox agree-checkbox-new">
              <b-form-checkbox value="true" v-model="Profile.Attestation">By checking this box, you are confirming that you will conduct all acceptable measures of due diligence to ensure the changes made to the system for this Client, once unlocked, are limited only to what was specified & approved on this request.</b-form-checkbox>
          </div>
  <br>
  <br>
  <br>
  <br>

     </div>
  </div>

  <div class="form-group pt-60 text-right footer-buttons" style="padding-right:96px !important;">
  <button  class="btn btn-link text-uppercase"  @click="Cancel()">
      CANCEL
    </button>
  <button v-if="!this.requestid || isClone == true"
      :disabled="!IsFormSigned || this.$v.$invalid || forminvalid() || formInvalidTimeFrame() || this.ShowAddProduct"
      class="text-uppercase btn custom-button btn-primary"
      @click="RequesttoUnlock()"
    >
      SUBMIT
    </button>
     <button v-if="this.requestid > 0 && isClone != true"
       class="text-uppercase btn custom-button btn-primary"
      :disabled="!IsFormSigned || this.$v.$invalid || forminvalid() || formInvalidTimeFrame() || this.ShowAddProduct"
       @click="UpdateProfileUnlock()">
          RESUBMIT
    </button>
  </div>
  <b-modal
    no-enforce-focus
    no-close-on-backdrop
    size="xl"
    v-model="ShowAddProduct"
    class="Title-Text"
    hide-footer
    title="Instructions"
    style="z-index:1;"
    @close="onClose"
  >
    <p v-if="errorinstruction1" class="You-cannot-submit-th" style="text-align:left;font-size:14px;font-family: 'Open Sans';">
      The vendor cannot be changed on a buy after any of these actions have been taken. : sent IO, sent financials, or sent for trafficking and placement is live.If you need to change vendor you need to add a new placement. In order to remove duplicate buys you can exclude them, send updated financials / IOs,whatever you may already have to cancel it, then you can delete it once those updates are in place.Alternatively you can zero them out, and rename “DNU” or “Do not use” so you don’t have duplicate names if the exclusion isn’t working to delete.
    </p>
    <p v-if="errorinstruction2" class="You-cannot-submit-th" style="text-align:left;font-size:14px;font-family: 'Open Sans';">
      This is just a warning message. Generally, only media B/Programmatic Bundled, uses the Cos2/Mark-up Feature.If the buys are for media B, the system is flagging that you forgot to link the Cos2/Marked up $. If the buys are not for media B and should not be using the Cos2/Mark-up Feature, you can just disregard.
    </p>
    <br />
    <div class="text-right">
      <button class="btn btn-link text-uppercase" @click="CancelAdd()">
        Cancel
      </button>
    </div>
  </b-modal>
  <b-modal
    no-enforce-focus
    no-close-on-backdrop
    size="xl"
    v-model="ShowContactVendor"
    class="Title-Text"
    hide-footer
    title="Contact Vendor"
    style="z-index:1;"
  >
    <p class="You-cannot-submit-th" style="text-align:left;font-size:14px;font-family: 'Open Sans';">
      Contact vendor for revised invoice or credit memo in order to continue.
    </p>

    <br />
    <div class="text-right">
      <button class="btn btn-link text-uppercase" @click="OnCloseContactVendor()">
        Cancel
      </button>
    </div>
  </b-modal>
  </div>

</template>


<style scoped>

.month-picker year button:last-child {
  display: none !important;
}
.tab-content {
  background-color: #ffffff !important;
  border-radius: 5px !important;
  margin-top: 20px !important;
  margin-left: 20px !important;
  margin-right: 20px !important;
}


.Profile-Unlock-Reque {
width: 159px;
height: 19px;
margin: 0 73px 6px 0;
font-family: 'Open Sans';
font-size: 14px;
font-weight: bold;
font-stretch: normal;
font-style: normal;
line-height: normal;
letter-spacing: normal;
color: 4d5161;
}

.Submit-this-request {
width: 232px;
height: 19px;
margin: 6px 0 0;
font-family: 'Open Sans';
font-size: 12px;
font-weight: normal;
font-stretch: normal;
font-style: normal;
line-height: 1.58;
letter-spacing: normal;
color: #4d5161;
}


.agencycontactwithtopmargin{
margin-top:-30px !important;
}

.agencycontactwithnottopmargin{
margin-top:-4px !important;
}

.withtopmargin{
margin-top:-22px !important;
}

.withnottopmargin{
margin-top:-4px !important;
}
.OMCLocator{
width: 498px;
  margin-left: -2px;
  font-size: 11px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: .8;
  letter-spacing: normal;
  margin-bottom: 6px;
  padding-left: 12px;
 color: #00a1d2;
  text-transform: uppercase;
}
.redtextbox {
width: 415px !important;
height: 35px !important;
border: 1px solid #df5678 !important;
}

.notredtextbox {
border: none !important;
}

.fieldborder {
padding-bottom: 15px !important;
}

.customdropzone {
/* height: 44px !important; */
border-radius: 8px !important;
border: dashed 2px #e9eef0 !important;
background-color: #ffffff !important;
cursor: pointer !important;
}

.Drag-and-drop-file-h {
margin-left: 18px !important;
margin-top: 14px !important;
width: 387px !important;
height: 10px !important;
font-family: "Open Sans" !important;
font-size: 14px !important;
font-weight: normal !important;
font-stretch: normal !important;
font-style: normal !important;
line-height: 0.71 !important;
letter-spacing: normal !important;
/* color: #a2a9ad !important; */
}


.zipcodevalidationmsg {
/* margin-top: -20px !important; */
width: 157px !important;
font-size: 10px !important;
color: #df5678 !important;
font-family: "Open Sans" !important;
font-size: 11px !important;
font-weight: normal !important;
font-stretch: normal !important;
font-style: italic !important;
line-height: 0.8 !important;
letter-spacing: normal !important;
text-align: left !important;
}

.error-message1 {
height: 14px !important;
font-size: 10px !important;
font-weight: normal;
font-stretch: normal;
font-style: normal;
line-height: normal;
letter-spacing: normal;
color: #df5678 !important;
}

.error-message {
height: 14px !important;
font-size: 10px !important;
font-weight: normal;
font-stretch: normal;
font-style: normal;
line-height: normal;
letter-spacing: normal;
color: #df5678 !important;
}

.draganddropspan {
color: #00a1d2;
}
.formatDollars {
  background-image: url("data:image/svg+xml;utf8,<svg \ xmlns='http://www.w3.org/2000/svg' \ version='1.1' height='40px' width='40px'>\ <text x='2' y='22' \ fill='gray' font-size='15'>$</text></svg>");
  background-repeat: no-repeat;
}

</style>

<script>
import authenticateAdUser from "../../mixins/aduserauthorization";
import { mapGetters } from "vuex";
import moment from 'moment'
import { required,requiredIf } from "vuelidate/lib/validators";
import { NETMedia, PRINTMedia } from "../../utilities/MediaList";
import vue2Dropzone from "vue2-dropzone";
import "vue2-dropzone/dist/vue2Dropzone.min.css";
import store from "@/store";
import service from "../../services/Dropdowns";
import VueMonthlyPicker from 'vue-monthly-picker';
import { TheMask } from 'vue-the-mask'
export default {
  mixins: [authenticateAdUser],
validations: {
  Profile: {
    agency:{
      required
    },
    changingdoller:{
      required
    },
    additionalbilling:{
      required
    },


    clearedbuy:{
      required
    },
    RevisedInvoice: {
       required: requiredIf(function(){
          return this.Profile.clearedbuy == 'true' || this.Profile.clearedbuy == true
       }),
    },
    timeperiodefile :{
       required: requiredIf(function(){
          return this.TimeFrameddl == 0 && this.Profile.RevisedInvoice == 'true' || this.Profile.RevisedInvoice == true
       }),
    },
    entity:{
      required
    },
    toolkit:{
      required
    },
    SupplierName: {
        required: requiredIf(function(model){
          return model.toolkit != null;
        }),
    },
    NetMediaTypes:{
      required: requiredIf(function (model) {
                  return model.toolkit == "Net";
              }),
    },
    PrintMediaTypes:{
      required: requiredIf(function (model) {
                  return model.toolkit != 'Net';
              }),
    },
    usererror:{
       required: requiredIf(function(model){
          return model.error=="None"
       })
    },
    clientcode:{
      required
    },
    reason:{
      required
    },
    OriginalBuyAmount:{
       required: requiredIf(function(){
          return this.Profile.changingdoller == 'true' || this.Profile.changingdoller == true
       })
    },
    NewBuyAmount:{
       required: requiredIf(function(){
          return this.Profile.changingdoller == 'true' || this.Profile.changingdoller == true
       })
    },
    camplink:{
       required: requiredIf(function(){
          return this.PrintMediaLsit && this.Profile.PrintMediaTypes != 'Outdoor'
       })
    },
    BPMApprover:{
      required
    }
  },
},
props: {
  requestid: {
    type: Number,
    required: false,
  },
  isClone: {
    type: Boolean,
    required: false
  }
},
components: {
  vueDropzone: vue2Dropzone,
  VueMonthlyPicker,
  TheMask

},

mounted() {
  this.formatDollars('OriginalBuyAmount');
  this.LoadBPMUsers();
  this.LoadEntityValues();
  this.LoadClientCodes();
    if (this.requestid > 0 ) {
      this.GetProfileUnlockRequestWithTaskDetails();
    }
  this.$parent.$emit("stepInitiated", this.$route.meta.tabIndex);
  var thElm;
  var startOffset;
  if(this.id && parseInt(this.id)>0){
  this.prodbilling=this.ClientBasicDetails.ProductionBilling;
  }
  Array.prototype.forEach.call(
    document.querySelectorAll("table th"),
    function (th) {
      th.style.position = "relative";

      var grip = document.createElement("div");
      grip.innerHTML = "&nbsp;";
      grip.style.top = 0;
      grip.style.right = 0;
      grip.style.bottom = 0;
      grip.style.width = "5px";
      grip.style.position = "absolute";
      grip.style.cursor = "col-resize";
      grip.addEventListener("mousedown", function (e) {
        thElm = th;
        startOffset = th.offsetWidth - e.pageX;
      });

      th.appendChild(grip);
    }
  );

  document.addEventListener("mousemove", function (e) {
    if (thElm) {
      thElm.style.width = startOffset + e.pageX + "px";
    }
  });

  document.addEventListener("mouseup", function () {
    thElm = undefined;
  });
},
data() {
  return {
    isDisable: false,
    IsAdditionalBillingNotSelected: false,
    IsAdditionalBillingSelected: false,
    isDisplayInput: true,
    locale: ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'],
    alignment: 'left',
    selectedBackgroundColor: 'lightgreen',
    options: [
      {
        id: 1,
        title: 'English',
        monthLabels: ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec']
      }

    ],
    alignments: ['left', 'center', 'right'],
    colorExamples: ['blue', 'red', 'black'],
    min: moment("01/01/1950"),
    max:moment(new Date().toString()),
    isLimitRange: true,
    clearOption: true,

    validationtextbox:false,
    TimePart:[
        { label: "January", value: "January"},
        { label: "February", value: "February"},
        { label: "March", value: "March"},
        { label: "April", value: "April"},
        { label: "May", value: "May"},
        { label: "June", value: "June"},
        { label: "July", value: "July"},
        { label: "August", value: "August"},
        { label: "September", value: "September"},
        { label: "October", value: "October"},
        { label: "November", value: "November"},
        { label: "December", value: "December"},
    ],
    ErrorMessages: [
           { label: "Print/Digital- Profile does not allow rate changes", value: "Print/Digital- Profile does not allow rate changes"} ,
           { label: "Print/Digital- Month is actualized. Planned Cost can't be edited", value: "Print/Digital- Month is actualized. Planned Cost can't be edited"} ,
           { label: "Print/Digital- Cannot Delete, Insertion linked to Invoice ", value: "Print/Digital- Cannot Delete, Insertion linked to Invoice" },
           { label: "Print/Digital- Unable to update vendor/supplier", value: "Print/Digital- Unable to update vendor/supplier" },
           { label: "Print/Digital- COS2 not updated for COS2 client", value: "Print/Digital- COS2 not updated for COS2 client" },
           { label: "Buy is Reconciled", value: "Buy is Reconciled" },
           { label: "Network-Affidavit time is set; cannot chance Actual Cost", value: "Network-Affidavit time is set cannot chance Actual Cost" },
           { label: "Network-Unit is paid; Cannot change Product Code", value: "Network-Unit is paid Cannot change Product Code" },
           { label: "None of the above", value: "None" }
        ],
    NetMediaLists:[
         { label: "Cable", value: "Cable" },
         { label: "National", value: "National" },
         { label: "Syndication", value: "Syndication" }
    ],
    PrintMediaLists:[
        { label: "Digital Audio", value: "Digital Audio" },
        { label: "Interactive", value: "Interactive" },
        { label: "Magazine", value: "Magazine" },
        { label: "NewsPaper", value: "NewsPaper" },
        { label: "Outdoor", value: "Outdoor" },
        { label: "Programmatic Bundled", value: "Programmatic Bundled" },
        { label: "Programmatic Unbundled", value: "Programmatic Unbundled" },
        { label: "Search", value: "Search" },
        { label: "Social", value: "Social" },
        { label: "Trade", value: "Trade" },
        { label: "Video", value: "Video" },
    ],

  Agencies:[ ],
  ClientCodes:[],

        ToolKits: [
           { label: "Net", value: "Net" },
           { label: "Print/Prisma", value: "PrintPrisma" }
        ],
    isedit:true,
    ProfileRequestId:0,
    ProfileCurrentFiles:[],
    commissionInfo :[],
    ProductInfo:[],
    BillingInfo:{},
    clientForm:{},
    ddl:0,
    TimeFrameddl:0,
    showprodbilling: false,
    NetMediaList:false,
    PrintMediaLsit:false,
    prodbilling: false,
    deleteIcon: require("@/assets/Icons/delete.svg"),
    collapseIcon: require("@/assets/Icons/arrow-down.svg"),
    expandIcon: require("@/assets/Icons/rightarrow.svg"),
    TimeOnly: null,
    errorinstruction1:false,
    errorinstruction2:false,
    ShowAddProduct:false,
    ShowContactVendor:false,
    ShowDisclaimer:false,
    RemoveFileList: [],
    dropzoneOptions: {
      closeFilterIcon: require("@/assets/Icons/file.png"),
      previewTemplate: this.template(),
      url:  `${process.env.VUE_APP_API_ROOT}api/v1/ProfileUnlock/ProfileUpload`,
      uploadMultiple: true,
      maxFiles: 5,
      minFiles: 1,
      maxFilesize: 10,
      addRemoveLinks: false,
      parallelUploads: 5,
      createImageThumbnails: false,
      autoProcessQueue: false,
      init: function () {
         this.on("addedfile", function(file) {
          if(this.files.length>5)
          {
              this.removeFile(file);
              window.alert('Maximum File Upload limit is 5!');
          }
      });
      },
  },
    fields: [
      // { key: "Actions", label: ""},
      { key: "MediaCategory", label: "Media", sortable: true },
      { key: "FeeOnly", label: "Fee Only", sortable: true },
      { key: "CommissionPercentage", label: "Commission %", sortable: true },
      { key: "CommissionBasis", label: "Commission Basis", sortable: true },
      {
        key: "DefaultPaymentTerms",
        label: "Default Payment Terms",
        sortable: true,
      },
    ],
    FeeOnly: null,
    CommissionBasisOptions: [
      { text: "NET", value: "Net" },
      { text: "GROSS", value: "Gross" },
    ],
    purchase:["Cable","National"],
    OMDEntity:[],
    NETList: [],
    SPOTList: [],
    PRINTList: [],
    BPMUsers: [],
    SelectedNETList: [],
    SelectedSPOTList: [],
    SelectedPRINTList: [],
    FilesUploaded: [],
    CurrentFilesList: [],
    CommissionBasis: null,
    NetMedia: "NET",
    PrintMedia: "PRINT",
    OmniflowProcessId:0,
    OmniflowProjectId:0,
    CommissionPercentage: null,
    Profile:{
              AgencyName:null,
              error:null,
              entity:null,
              agency:null,
              toolkit:null,
              NetMediaTypes:null,
              PrintMediaTypes:null,
              clientcode:null,
              reason:null,
              camplink:null,
              usererror:null,
              changingdoller:null,
              clearedbuy:null,
              additionalbilling:null,
              month: moment(),
              RevisedInvoice:null,
              NewBuyAmount:null,
              OriginalBuyAmount:null,
              BPMApprover:[],
              Product:null,
              Estimate:null,
              Attestation:null,
          }
  };
},
computed: {
    IsFormSigned() {
        return this.Profile.Attestation;
    },
    canReadProfileRequest() {
      return this.$store.getters.canReadProfileRequest;
    },
   rangeDisplay () {
    if (!this.min || !this.max) {
      return 'Disabled'
    }
    return this.min.format('YYYY/MM') + '-' + this.max.format('YYYY/MM')
  },

  // showprodbilling(){
  //  return this.ClientCommissionInfo!=null && this.ClientCommissionInfo.length>0;
  // },
  MediaList() {
    let list = [];
    list.push({ label: "Net", value: NETMedia });
    return list;
  },
  PrintMediaList(){
    let list = [];
    list.push({ label: "Print", value: PRINTMedia });
    return list;
  },
  IsAllNetMediaNotSelected() {
    return this.NETList != null && this.NETList.length > 0;
  },

  IsAllPrintMediaNotSelected() {
    return this.PRINTList != null && this.PRINTList.length > 0;
  },
  ...mapGetters("client", {
    ClientCommissionInfo: "clientCommissionInfo",
    PaymentTerms: "PaymentTerms",
    ClientProductList: "clientProductList",
    BillingDefaultPaymentTerms:
      "ClientBillingInformationsDefaultPaymentTerms",
    ClientBasicDetails:"ClientBasicDetails"
  }),

  ...mapGetters("client", {
    clientinfopagetwo: "ClientEularInfo",
  }),

  ...mapGetters("client", {
    clientinfopageone: "ClientBasicDetails",
  }),

   ...mapGetters("client", {
    clientinfopagethree: "ClientBillingInformations",
  }),

  SelectedMedia() {
    return (
      this.TimeOnly == "true" ||
      this.IsMediaUsed(this.SelectedNETList) |
        this.IsMediaUsed(this.SelectedPRINTList) ||
      this.IsMediaUsed(this.SelectedSPOTList)
    );
  },
  CommissionBasisList() {
    return this.CommissionBasisOptions.map((val) => {
      return { label: val.text, value: val.value };
    });
  },
  TimeOnlySelected() {
    let timeOnlyIndex = this.ClientCommissionInfo.findIndex((media) => {
      return media.MediaCategory == "TimeOnly";
    });
    return timeOnlyIndex != -1;
  },
  ProductSelectedMedia() {
    let mediaSelected = [];
    if (this.ClientProductList != null && this.ClientProductList.length > 0) {
      this.ClientProductList.forEach((val) => {
        val.Media.forEach((media) => {
          mediaSelected.push(media.MediaType);
        });
      });
    }
    return mediaSelected;
  },
},
activated() {
  this.$parent.$emit("stepInitiated", this.$route.meta.tabIndex);
},
created() {
  this.$parent.$emit("stepInitiated", this.$route.meta.tabIndex);

},
watch: {
  "Profile.agency": function(val) {
    this.UpdateAgencyName(val);
  },
  "Profile.clearedbuy": function(val, oldval) {
    if(val && oldval != null) {
      this.Profile.RevisedInvoice=null;
      this.$refs.TimeFramemyVueDropzone.removeAllFiles();
    }
  },
  "Profile.RevisedInvoice":function(val,oldval){
    if((this.Profile.clearedbuy == 'true' || this.Profile.clearedbuy == true)
        && (val == false || val == 'false')) {
        this.ShowContactVendor=true;
      } else {
        this.ShowContactVendor=false;
      }
      if(val && oldval != null) {
        this.$refs.TimeFramemyVueDropzone.removeAllFiles();
      }
  },
  "Profile.changingdoller":function(val) {
    if(val == 'false') {
      this.Profile.NewBuyAmount = "";
      this.Profile.OriginalBuyAmount = "";
      this.Profile.additionalbilling = 'false';
      if(this.IsAdditionalBillingNotSelected == true) {
        this.IsAdditionalBillingNotSelected = false;
        this.IsAdditionalBillingSelected = true;
      } else {
        this.IsAdditionalBillingSelected = true;
      }
    }
    if(val == 'true') {
      this.Profile.additionalbilling = 'true';
      if(this.IsAdditionalBillingSelected == true) {
        this.IsAdditionalBillingSelected = false;
        this.IsAdditionalBillingNotSelected = true;
      } else {
        this.IsAdditionalBillingNotSelected = true;
      }
    }
  },
    isLimitRange (newValue) {
    if (newValue) {
      this.setSelectRange(moment().subtract(6, 'months'), moment().add(6, 'months'))
    } else {
      this.setSelectRange(null, null)
    }
  },
  // "Profile.clearedbuy":function(oldval,newval){
  //   if(oldval != newval)
  //   {
  //     this.Profile.RevisedInvoice=null;
  //     this.Profile.addresschange=null;
  //   }
  // },

  //  "Profile.RevisedInvoice":function(oldval,newval){
  //   if(oldval != newval)
  //   {
  //     this.Profile.addresschange=null;
  //   }
  // },

  "Profile.toolkit":function(val){
     if(val=="Net")
     {
       this.NetMediaList=true;
       this.PrintMediaLsit=false;
       this.Profile.MediaTypes=null;
     }
     else{
       this.PrintMediaLsit=true;
       this.NetMediaList=false;
       this.Profile.MediaTypes=null;
     }
  },



  FeeOnly(value) {
    this.ResetCommissionBasis(value);
  },
  CommissionBasis(value) {
    this.ResetFeeOnly(value);
  },
  TimeOnly(val) {
    if (val == "true") {
      this.ResetObject();
    }
  },
  prodbilling(val){
    this.ClientBasicDetails.ProductionBilling=val;
  }
},
methods: {
   template: function () {
    return `<div class="dz-preview dz-file-preview" style='background-color:#ffffff !important;display:flex;'>

              <div class="dz-details" style='margin-right:79px !important;'>
                  <div class="dz-size" style="display:none;" ><span data-dz-size></span></div>
                  <div class="FileforNewClientxls-Copy" style="margin-left:20px;width:280px;cursor:default !important;" ><span style='word-break:break-all !important;font-size:14px !important;font-weight:400;font-stretch:normal;font-style: normal;line-height: 0.71;color: #00a1d2 !important; font-family:"Open Sans" !important;' data-dz-name></span></div>
              </div>
              <a>
               <img data-dz-remove=""  style='' src='/icons/fileremove.svg'  @click="DeleteServerFile()" ></img>
              </a>
          </div>`;
  },



  ValidateYear(){
          if (this.Profile.year.length != 0 && this.Profile.year.length < 4) {
              this.validationtextbox = true;
          } else {
              this.validationtextbox = false;
          }
  },
  SetDirty(property) {
    this.$v.Profile[property].$touch();
  },

  ResetDirty(property) {
    this.$v.Profile[property].$reset();
  },


  onClose(){
   this.Profile.error=null;
  },
  OnCloseContactVendor() {
    this.ShowContactVendor = false;
  },
  ErrorCheck(){
  this.Profile.usererror=null;
   if(this.Profile.error=="Print/Digital- Unable to update vendor/supplier")
    {
      this.errorinstruction1=true;
      this.errorinstruction2=false;
      this.ShowAddProduct=true;
      this.ShowDisclaimer=false;
    }
    else if(this.Profile.error=="Print/Digital- COS2 not updated for COS2 client")
    {
      this.errorinstruction1=false;
      this.errorinstruction2=true;
      this.ShowAddProduct=true;
      this.ShowDisclaimer=false;
    }
    else if(this.Profile.error=="None")
    {
      this.ShowDisclaimer=true;
      this.errorinstruction1=false;
      this.errorinstruction2=false;
      this.ShowAddProduct=false;
    }
    else
    {
      this.ShowAddProduct=false;
      this.errorinstruction1=false;
      this.errorinstruction2=false;
      this.ShowDisclaimer=false;
    }

  },
  async LoadEntityValues() {
    service
      .getOriginalEntityRecords("Financial Entity")
      .then((res) => {
        for(var index in res.data) {
            var entityitem = res.data[index];
            this.OMDEntity.push({
            "label" :  entityitem.label,
            "value"  : entityitem.value,
        });
       }
        this.OMDEntity = this.OMDEntity;
      })
      .catch((err) => {
        alert(err);
      });
  },

  EntityChange(){
    var entityid = this.Profile.entity;
    service
      .getAgencyDropdownValues(entityid, entityid)
      .then((res) => {
        this.Agencies=[];
        this.Agencies=res.data;
        this.UpdateAgencyName(this.Profile.agency)

      }).catch((err) => {
        alert(err);
      });
  },
  UpdateAgencyName(newVal) {
      const agencyValue = this.Agencies.findIndex((item) => {
          return item.value == newVal;
      });
      if (agencyValue > -1) {
          this.Profile.AgencyName = this.Agencies[agencyValue].label;
      } else {
          this.Profile.AgencyName = "";
      }
  },
  async LoadClientCodes() {
          this.$store
              .dispatch("product/GetClients")
              .then((response) => {
                  this.ClientCodes = response.data;
                  this.ClientCodes.forEach(element => { element.value = element.label; });
              })
              .catch((ex) => {
                  window.console.log(`Error is ${ex}`);
              });
  },

   ManuallyUploadFiles(files) {
          if (files != null && files.length > 0) {
              files.forEach((fileUploaded) => {
                  let file = {
                      size: fileUploaded.Length,
                      name: fileUploaded.Name,
                      type: fileUploaded.Extension,
                  };
                  var url = fileUploaded.FullName;
                  this.FilesUploaded.push(fileUploaded.Name);
                  this.$refs.myVueDropzone.manuallyAddFile(file, url);
                  this.CurrentFilesList.push(fileUploaded.Name);
              });
          }
    },

    ManuallyUploadTimeFrameFiles(files) {
          if (files != null && files.length > 0) {
              files.forEach((fileUploaded) => {
                  let file = {
                      size: fileUploaded.Length,
                      name: fileUploaded.Name,
                      type: fileUploaded.Extension,
                  };
                  var url = fileUploaded.FullName;
                  this.FilesUploaded.push(fileUploaded.Name);
                  this.$refs.TimeFramemyVueDropzone.manuallyAddFile(file, url);
                  this.CurrentFilesList.push(fileUploaded.Name);
              });
          }
    },


  UploadedtoDropzone(){
      this.ddl = this.$refs.myVueDropzone.dropzone.files.length;
      //this.ProfileCurrentFiles=this.GetFilesObject();
      this.$set(this.Profile, "ErrorFiles", this.GetFilesObject());
  },

  UploadedtoTimePeriodeDropzone(){
      this.TimeFrameddl = this.$refs.TimeFramemyVueDropzone.dropzone.files.length;
      //this.ProfileCurrentFiles=this.GetFilesObject();
      this.$set(this.Profile, "TimeFrameFiles", this.GetTimeFrameFilesObject());
  },

GetTimeFrameFilesObject() {
          let files = [];
          if (this.$refs.TimeFramemyVueDropzone.dropzone.files && this.$refs.TimeFramemyVueDropzone.dropzone.files.length > 0) {
              let filesExists = this.$refs.TimeFramemyVueDropzone.dropzone.files;
              for (let i = 0; i < filesExists.length; i++) {
                  files.push({
                      name: filesExists[i].name,
                      file: filesExists[i]
                  });
              }
              return files;
          } else {
              return files;
          }
},

GetFilesObject() {
          let files = [];
          if (this.$refs.myVueDropzone.dropzone.files && this.$refs.myVueDropzone.dropzone.files.length > 0) {
              let filesExists = this.$refs.myVueDropzone.dropzone.files;
              for (let i = 0; i < filesExists.length; i++) {
                  files.push({
                      name: filesExists[i].name,
                      file: filesExists[i]
                  });
              }
              return files;
          } else {
              return files;
          }
      },
  // RemovedFromDropzone(){
  //   this.ddl = this.$refs.myVueDropzone.dropzone.files.length;
  // },

  RemovedFromDropzone(file) {
    this.ProfileCurrentFiles=this.GetFilesObject();
          if (this.isdestroying != true) {
              this.ddl = this.$refs.myVueDropzone.dropzone.files.length;
              this.RemoveFileList.push(file.name);
              let fileIndex = this.CurrentFilesList.indexOf(file.name);
              if (fileIndex != -1) {
                  this.CurrentFilesList.splice(fileIndex, 1);
              }
          }

  },


  RemovedFromTimePeriodeDropzone(file){
     this.ProfileCurrentFiles=this.GetTimeFrameFilesObject();
          if (this.isdestroying != true) {
              this.TimeFrameddl = this.$refs.TimeFramemyVueDropzone.dropzone.files.length;
              this.RemoveFileList.push(file.name);
              let fileIndex = this.CurrentFilesList.indexOf(file.name);
              if (fileIndex != -1) {
                  this.CurrentFilesList.splice(fileIndex, 1);
              }
          }
  },

    forminvalid(){
      if(this.ddl>0)
      {
        return false;
      }
      else{
        return true;
      }
    },
   formInvalidTimeFrame(){
     if(this.Profile.clearedbuy=='true') {
        if(this.Profile.RevisedInvoice=='true') {
          if(this.TimeFrameddl>0)
          {
            return false;
          }
          else{
            return true;
          }
        }
      else {
        return true;
      }
     } else {
      return false
     }
    },
 Cancel(){
     this.RemoveFileList = [];
    this.$router.push("/ProfileUnlock");
 },

 CancelAdd(){
  this.ShowAddProduct=false;
  this.Profile.error=null;
 },



 GetProfileUnlockRequestWithTaskDetails() {
   var inputObj = {
              id: parseInt(this.requestid),
              isedit: this.isedit,
          };
    this.$store
      .dispatch(
        "client/GetProfileUnlockRequestWithTaskDetails",
        inputObj
      )
      .then((res) => {
        this.Profile.entity=res.data.Entity;
        this.EntityChange();
        this.Profile.error=res.data.UnlockErrorMessage;
        if(res.data.Toolkit=="Net")
        {
          this.Profile.NetMediaTypes=res.data.UnlockMediaType;
        }
        else
        {
          this.Profile.PrintMediaTypes=res.data.UnlockMediaType;
        }
        let NewBPMApprover=[];
        let OldBPMApproverList = res.data.BPMApprover.split(',').map(item => item.trim());
        let transformedBPMApproverList = OldBPMApproverList.map(item => ({
          value: item,
          label: item
        }));
        transformedBPMApproverList.forEach(el => {
          NewBPMApprover.push(el.value)
        })
        this.Profile.timeframe=res.data.TimeFrame;
        this.Profile.agency=res.data.Agency;
        if(this.isClone != true){
          this.Profile.month=res.data.Month;
          this.Profile.year=res.data.Year;
        }
        this.Profile.toolkit=res.data.Toolkit;
        this.Profile.SupplierName=res.data.SupplierName;
        this.Profile.clientcode=res.data.ClientCode;
        this.Profile.reason=res.data.Reason;
        this.Profile.camplink=res.data.Camplink;
        this.OmniflowProcessId=res.data.OmniflowProcessID;
        this.OmniflowProjectId=res.data.OmniflowProjectID;
        this.Profile.usererror=res.data.UserError;
        this.Profile.changingdoller=res.data.ChangingDoller == true ? "true":"false";
        this.Profile.clearedbuy=res.data.ClearedBuy==true? "true":"false";
        this.Profile.additionalbilling=res.data.AdditionalBilling==true? "true":"false";
        this.Profile.RevisedInvoice=res.data.RevisedInvoice == true ? "true":"false";
        this.Profile.NewBuyAmount=res.data.NewBuyAmount;
        this.Profile.OriginalBuyAmount=res.data.OriginalBuyAmount;
        this.Profile.BPMApprover=NewBPMApprover;
        this.Profile.Product=res.data.Product;
        this.Profile.Estimate=res.data.Estimate;
        if(res.data.UserError !=null && res.data.UnlockErrorMessage=="None")
        {
          this.ShowDisclaimer=true;
        }
        else{
          this.ShowDisclaimer=false;
        }
        if (res.data.ErrorFiles.length > 0 && this.isClone != true) {
              this.ManuallyUploadFiles(res.data.ErrorFiles);
              this.ddl = res.data.ErrorFiles.length;
        }
        if (res.data.RevisedInvoice==true &&  res.data.TimeFrameFiles.length > 0 && this.isClone != true) {
              this.ManuallyUploadTimeFrameFiles(res.data.TimeFrameFiles);
              this.TimeFrameddl = res.data.TimeFrameFiles.length;
        }
      })
      .catch((err) => {
          if (err.response && err.response.status == 403) {
            this.$router.push({
              name: "error",
              params: {
                message: "UnAuthorized"
              },
            });
          }
          else{
            this.Error = true;
            alert(err);
          }
        });
  },

 UpdateProfileUnlock(){
    let ProfileUnlock={};
    const BPMUserDataObject = Object.values(this.Profile.BPMApprover).join(", ");
    ProfileUnlock.ID=parseInt(this.requestid);
    ProfileUnlock.Requestor="";
    ProfileUnlock.UnlockErrorMessage=this.Profile.error;
    ProfileUnlock.ChangingDoller=this.Profile.changingdoller=="true"? true : false;
    ProfileUnlock.ClearedBuy=this.Profile.clearedbuy=="true"? true : false;
    ProfileUnlock.AdditionalBilling=this.Profile.additionalbilling=="true"?true:false;
    ProfileUnlock.RevisedInvoice=this.Profile.RevisedInvoice=="true"?true:false;
    ProfileUnlock.TimeFrame=this.Profile.timeframe=="true"?true:false;
    ProfileUnlock.UnlockMediaType=this.Profile.toolkit=='Net'? this.Profile.NetMediaTypes:this.Profile.PrintMediaTypes;
    ProfileUnlock.Month=this.Profile.month;
    ProfileUnlock.Year=this.Profile.year;
    ProfileUnlock.Entity=this.Profile.entity;
    ProfileUnlock.Agency=this.Profile.agency;
    ProfileUnlock.AgencyName=this.Profile.AgencyName;
    ProfileUnlock.Toolkit=this.Profile.toolkit;
    ProfileUnlock.SupplierName=this.Profile.SupplierName;
    ProfileUnlock.ClientCode=this.Profile.clientcode;
    ProfileUnlock.Reason=this.Profile.reason;
    ProfileUnlock.Camplink=this.Profile.camplink;
    ProfileUnlock.OmniflowProcessID=this.OmniflowProcessId;
    ProfileUnlock.OmniflowProjectID=this.OmniflowProjectId;
    ProfileUnlock.UserError=this.Profile.usererror;
    ProfileUnlock.NewBuyAmount=this.Profile.NewBuyAmount;
    ProfileUnlock.OriginalBuyAmount=this.Profile.OriginalBuyAmount;
    ProfileUnlock.BPMApprover=BPMUserDataObject;
    ProfileUnlock.Product=this.Profile.Product;
    ProfileUnlock.Estimate=this.Profile.Estimate;
    //////////////////////File Upload Code
    ProfileUnlock.Files=[];
    this.GetFilesCategory().forEach(name => {
              this.AttachUpdatedFiles(ProfileUnlock.Files, name);
          });
    let formData = new FormData();
     this.GetFilesCategory().forEach(name => {
              this.AttachFiles(formData, name);
          });
          // let files = this.$refs.myVueDropzone.dropzone.files;
          // for (var i = 0; i < files.length; i++) {
          //     let file = files[i];

          //     formData.append('ProfileFiles[' + i + ']', file);
          // }

          // for (var j = 0; j < this.ProfileCurrentFiles.length; j++) {
          //     let file = this.ProfileCurrentFiles[j];
          //      window.console.log(JSON.stringify(file));
          //     ProfileUnlock.Files.push(file);
          // }
          //formData.append('CurrentProfileFiles[' + i + ']', file);
          formData.append('request', JSON.stringify(ProfileUnlock));

          this.$store
      .dispatch("client/UpdateProfileWithfile", { id: parseInt(this.requestid), data:formData})
      .then(() => {
        this.$router.push("/ProfileUnlock");
        store.dispatch(
          "progress/setSuccessMessage",
          "Your Profile Unlock request has been Updated Successfully!"
        );
      })
      .catch((ex) => {
        if (ex.response) {
          var commenmsg="";
          if(ex.response.status==500)
          {
             commenmsg="Please check your inputs or try again later";
             store.dispatch("progress/setErrorMessage", `${commenmsg}`);
          }
          else if(ex.response.status==400)
          {
            for(var i=0; i<ex.response.data.length;i++)
              {
                if(ex.response.data[i].ErrorMessage != 'undefined')
                  {
                   commenmsg += ex.response.data[i].ErrorMessage;
                  }
              }
              store.dispatch("progress/setErrorMessage", `${commenmsg}`);
          }
           else{
            store.dispatch("progress/setErrorMessage", `${ex.response.data}`);
          }
        } else {
          store.dispatch(
            "progress/setErrorMessage",
            `Error while creating profile Unlock Request. ${ex}`
          );
        }
      });


 },


  handleSelect (value) {
    window.console.log('Select', value)
  },
  setSelectRange (min, max) {
    this.min = min
    this.max = max
  },

  GetFilesCategory() {
          return ["ErrorFiles", "TimeFrameFiles"];
      },

       AttachFiles(formData, fileCategory) {
         let files;
         if(fileCategory=="ErrorFiles")
         {
            files = this.$refs.myVueDropzone.dropzone.files;
         }
         else
         {
            files = this.$refs.TimeFramemyVueDropzone.dropzone.files;
         }

          for (var i = 0; i < files.length; i++) {
              let file = files[i];
              formData.append(`${fileCategory}[${i}]`, file.file || file);
          }
      },
      AttachUpdatedFiles(filesArray, fileCategory) {
        let files;
         if(fileCategory=="ErrorFiles")
         {
            files = this.$refs.myVueDropzone.dropzone.files;
         }
         else
         {
            files = this.$refs.TimeFramemyVueDropzone.dropzone.files;
         }
        for (var i = 0; i < files.length; i++) {
          let file = files[i];
          filesArray.push({ Category: fileCategory, Name: file.Name || file.name })
        }
      },
 RequesttoUnlock(){
    let ProfileUnlock={};
    const BPMUserDataObject = Object.values(this.Profile.BPMApprover).join(", ");
    ProfileUnlock.ChangingDoller=this.Profile.changingdoller=="true"? true : false;
    ProfileUnlock.ClearedBuy=this.Profile.clearedbuy=="true"? true : false;
    ProfileUnlock.AdditionalBilling=this.Profile.additionalbilling=="true"?true:false;
    ProfileUnlock.TimeFrame=this.Profile.timeframe=="true"?true:false;
    ProfileUnlock.RevisedInvoice=this.Profile.RevisedInvoice=="true"?true:false;
    ProfileUnlock.Month=this.Profile.month.format("YYYY-MM");
    ProfileUnlock.Year= this.Profile.month.format("YYYY");
    ProfileUnlock.ID = 0;
    ProfileUnlock.Requestor="";
    ProfileUnlock.UnlockErrorMessage=this.Profile.error;
    ProfileUnlock.UnlockMediaType=this.Profile.toolkit=='Net'? this.Profile.NetMediaTypes:this.Profile.PrintMediaTypes;
    ProfileUnlock.Entity=this.Profile.entity;
    ProfileUnlock.Agency=this.Profile.agency;
    ProfileUnlock.AgencyName=this.Profile.AgencyName;
    ProfileUnlock.Toolkit=this.Profile.toolkit;
    ProfileUnlock.SupplierName=this.Profile.SupplierName;
    ProfileUnlock.ClientCode=this.Profile.clientcode;
    ProfileUnlock.Reason=this.Profile.reason;
    ProfileUnlock.Camplink=this.Profile.camplink;
    ProfileUnlock.UserError=this.Profile.usererror;
    ProfileUnlock.NewBuyAmount=this.Profile.NewBuyAmount;
    ProfileUnlock.OriginalBuyAmount=this.Profile.OriginalBuyAmount;
    ProfileUnlock.BPMApprover=BPMUserDataObject;
    ProfileUnlock.Product=this.Profile.Product;
    ProfileUnlock.Estimate=this.Profile.Estimate;

    //////////////////////////file upload Code ////////////
    let formData = new FormData();

      this.GetFilesCategory().forEach(name => {
              this.AttachFiles(formData, name);
          });

          formData.append('request', JSON.stringify(ProfileUnlock));
          this.$store
      .dispatch("client/AddProfileWithfile", formData)
      .then(() => {
        this.$router.push("/ProfileUnlock");
        store.dispatch(
          "progress/setSuccessMessage",
          "Your Profile request has been submitted Successfully!"
        );
      })
      .catch((ex) => {
        window.console.log('Error calling PRofile Create');
        window.console.log(JSON.stringify(ex));
          store.dispatch(
            "progress/setErrorMessage",
            `Error while creating Profile Unlock Request. ${ex}`
          );
      });
 },


 GetErorMessageObject(){
   let ErrorMsgObject=[];
   if(this.Profile.error && this.Profile.error.length>0)
     {
         this.Profile.error.forEach((internalerror) =>{
          ErrorMsgObject.push({
            ID: 0,
            ProfileUnlockRequesID : 0,
            ErrorMessage: internalerror,
            ErrorMessageCatagory: internalerror.split('-')[0].toString().split('/')[0].toString()
          });
         })
     }
   return ErrorMsgObject;
 },


 GetUsedMedia() {
    let selectedMedia = [];
    let NETtempList = [];
    let PRINTtempList = [];
    let completetempList = [];
    let commissionInfo = [];


    if (this.Profile.MediaTypes != null && this.Profile.MediaTypes.length > 0) {
      this.Profile.MediaTypes.forEach((media) => {
        completetempList.push({
          MediaCategory: this.GetMediaCategory(media),
          MediaType: media,
        });
      });
    }

    if (completetempList != null && completetempList.length > 0) {
      completetempList.forEach((obj) => {
        if (obj.MediaCategory == "NET") {
          if (!this.IsMediaAlreadyAdded(obj, this.NetMedia)) {
            NETtempList.push(
              this.CreateUserObject({ MediaType: obj.MediaType })
            );
          }
        }
        if (obj.MediaCategory == "PRINT") {
          if (!this.IsMediaAlreadyAdded(obj, this.PrintMedia)) {
            PRINTtempList.push(
              this.CreateUserObject({ MediaType: obj.MediaType })
            );
          }
        }
      });

      if (NETtempList.length > 0) {
        selectedMedia.push({
          MediaCategory: this.NetMedia,
          Media: NETtempList,
          _showDetails: true,
        });
      }

      if (PRINTtempList.length > 0) {
        selectedMedia.push({
          MediaCategory: this.PrintMedia,
          Media: PRINTtempList,
          _showDetails: true,
        });
      }
    }



      selectedMedia.forEach((mediaCategory) => {
         mediaCategory.Media.forEach((internalmedia) =>{
          commissionInfo.push({
            ID: 0,
            ProfileUnlockRequesID : 0,
            MediaType: internalmedia.MediaType,
            MediaCategory: mediaCategory.MediaCategory
          });
         })
      })
    return commissionInfo;
  },





   IsMediaAlreadyAdded(name, mediaType) {
    if (this.ClientCommissionInfo && this.ClientCommissionInfo.length > 0) {
      let mediaTypeFound = this.ClientCommissionInfo.findIndex((val) => {
        return val.MediaCategory == mediaType;
      });
      if (mediaTypeFound != -1) {
        let itemFound = this.ClientCommissionInfo[
          mediaTypeFound
        ].Media.findIndex((val) => {
          return val.MediaType == name;
        });
        return itemFound != -1;
      } else {
        return false;
      }
    } else {
      return false;
    }
  },

  CreateUserObject(data) {
      return {
        ...data,
      };

  },


  GetMediaCategory(mediaType) {
    let MediaCategory = this.FindMedia(NETMedia, mediaType, this.NetMedia);
    if (MediaCategory == "")
      MediaCategory = this.FindMedia(PRINTMedia, mediaType, this.PrintMedia);
    return MediaCategory;
  },

  FindMedia(mediaList, mediaType, mediaCategory) {
    let mediaFound = mediaList.findIndex((val) => {
      return val.value == mediaType;
    });
    if (mediaFound > -1) {
      return mediaCategory;
    } else {
      return "";
    }
  },
  async LoadBPMUsers() {
    let currentenv;
    if (window.location.href.indexOf("finsysreq-stage") > -1) {
      currentenv = "Development";
    }
    else if (window.location.href.indexOf("finsysreq-uat") > -1) {
      currentenv = "Development";
    }
    else if (window.location.href.indexOf("localhost") > -1) {
      currentenv = "Development";
    }
    else {
      currentenv = "Production";
    }
    service
      .getBPMUsers(currentenv,"ProfileUnlock")
      .then((res) => {
        this.BPMUsers = res.data;
      })
      .catch((err) => {
        alert(err);
      });
  },
    formatDollars(val) {
      const amt = this.Profile[val]
      if (amt && amt  != '') {
        var num = amt.toString().replace(',', '');
        num = Number(num);
        if (!isNaN(num)) {
          let temp = num.toLocaleString('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 });
          this.Profile[val] = temp;
        }
        else {
          this.Profile[val] = '';
        }
      }
    },

}
};
</script>


